<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
          <ayl-table :table="table">
            <div slot="ctrl-button" style="line-height: 32px">
              <el-link type="primary" @click="dialogExport=true">查询数据导出</el-link>
            </div>
          </ayl-table>
      </div>
    </div>
    <!--导出-->
    <el-dialog title="选择导出的数据" :visible.sync="dialogExport" width="540px" center>
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
        <el-checkbox :label="item.index" v-for="(item, idx) in checkList" :key="idx">{{item.value}}</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exportExcel" :loading="btnLoading">确 定</el-button>
        <el-button @click="dialogExport=false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mixins from '../../../utils/mixins'
  export default {
    mixins: [mixins.$exportCheckAll],
    data () {
      const vm = this
      return {
        dialogExport: false,
        checkList: [],
        checkIndex: [],
        btnLoading: false,
        nav: [{name: "出纳结算", path: ""}, {name: "出纳收付报表", path: ""}],
        table: {
          api: vm.$api.listSteelSettlementReport,
          query: {
            startTime: null,
            endTime: null,
            queryContent: null,
          },
          searchData: [
            {
              type: 'dateSection',
              title: '日期',
              isOptions: true,
              model: [],
              modelName: ['startTime', 'endTime'],
            },
            {
              type: 'input',
              model: 'queryContent', 
              placeholder: '公司账户'
            }],
          columns: [
            {
              title: '公司账户',
              key: 'accName',
              showTooltip: true,
            }, {
              title: '账户类型',
              key: 'accType',
            }, {
              title: '开户银行',
              key: 'accBank',
              showTooltip: true,
            }, {
              title: '账户账号',
              key: 'companyAcc',
              showTooltip: true,
            }, {
              title: '实付交易数',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view': true,
                    },
                    on: {
                      click: vm.goEdit.bind(this, ctx.row,'实付')
                    }
                  }, ctx.row.payedCount)
                ])
              }
            }, {
              title: '实付总金额',
              key: 'totalPayedAmount',
            }, {
              title: '实收交易数',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view': true,
                    },
                    on: {
                      click: vm.goEdit.bind(this, ctx.row,'实收')
                    }
                  }, ctx.row.receivedCount)
                ])
              }
            }, {
              title: '实收总金额',
              key: 'totalReceivedAmount',
            }, ]
        }
      }
    },
    methods: {
      //实付交易总额明细
      goEdit(val,index){
        this.$router.push({
          path: '/cashier-settlement/cashier-payment/cashier-payment-details',
          query: {
            startTime: this.table.query.startTime,
            endTime: this.table.query.endTime,
            tradeType: index,
            accId: val.accId,
          }
        })
      },
      // 导出
      async exportExcel(){
        this.btnLoading = true
        try {
          const url = await this.$api.listSteelExcel({
            params: {
              startTime: this.table.query.startTime,
              endTime: this.table.query.endTime,
              queryContent: this.table.query.queryContent,
            },
            excelKey: 'listSteelSettlementReport',
            indexList: this.checkIndex,
          })
          window.open(url)
          this.dialogExport = false
        }catch (e) {}
        this.btnLoading = false
      },
    },

    async mounted() {
      // this.table.query.startTime = this.$route.query.startTime,
      // this.table.query.endTime = this.$route.query.endTime,
      // this.table.query.accId = this.$route.query.accId,
      // await this.$search(this.table)
      
      // 导出
      this.$api.GetExcelExportKeyAndColumn({
        excelKey: "listSteelSettlementReport"
      }).then((res)=>{
        this.checkList = res.chsList
        this.checkIndex = this.checkList.map(e=>{
          return e.index
        })
      })
    },
    beforeRouteEnter (to, from, next) {
      if (from.name == 'Cashier-payment-details') {
        to.meta.isBack = true
      } else {
        to.meta.isBack = false
      }
      next()
    },
    async activated () {
      if (!this.$route.meta.isBack) {
        this.$set(this.table.query,'startTime',null),
        this.$set(this.table.query,'endTime',null),
        this.table.searchData[0].model = [],
        this.table.searchData[0].modelName = ['startTime','endTime'];
        // await this.$search(this.table)
      } else {
          // await this.$search(this.table)
          this.$route.meta.isBack = false // 重置详情页标识isBack
      }
    },

  }
</script>

<style lang='sass' scoped>
.content-box /deep/ .el-form-item
    width: 41%
    display: inline-block

/deep/ .el-dialog__header
    border-bottom: 1px solid $grayShallow3

/deep/.el-date-editor.el-input
    width: 160px
</style>
